import React from "react";
import dashboardpic from "./Data/dashboard.png.png";

export default function Dashboard() {
  return (
    <div>
      <img src={dashboardpic} style={{ width: "100vw", height: "100%" }}></img>
    </div>
  );
}
